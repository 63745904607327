import React from 'react'
import { Send } from 'react-feather'
import { useNavigate } from 'react-router-dom'

const ApplicationCheckCard = ({title, stage}) => {
    const navigate = useNavigate()

  return (
    <div class="card report-card" style={{height:"10rem"}}>
        <div class="card-body">
            <div class="row d-flex justify-content-center">
                <div class="col-8">
                    <p class="text-dark font-weight-semibold font-14">{title}</p>
                    {(stage === "rejected" || stage === 'approved') ?
                    <button class="btn btn-danger" onClick={() => navigate('/apply/admission-status')}>Check admission status</button>
                    : (stage === 'completed') ? 
                        <button class="btn btn-warning" disabled>Processing Application...</button>
                        :<button class="btn btn-info" disabled>Application not submitted</button>
                    }
                </div>
                <div class="col-4 align-self-center">
                    <div class="report-main-icon bg-light-alt">
                        <Send class="align-self-center icon-dual-pink icon-lg"></Send>  
                    </div>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default ApplicationCheckCard