import React, { useState } from "react";
import { useEffect } from "react";
import api from "../../../api/api";
import {
  GetApplicantDetails,
  GetApplicationFee,
  GetPaymentStatus,
  InitiateHNDConversionPayment,
  InitiateResultCheckingPayment,
} from "../../../api/application";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { toast } from "react-toastify";
import ApplicantPayment from "../components/ApplicantPayment";
import { useNavigate } from "react-router-dom";
import ResultPayment from "../components/ResultPayment";

function ApplicationPayment() {
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Proceed to payment");
  const [applicationFeeStatus, setApplicationFeeStatus] = useState("");
  const [resultFeeStatus, setresultFeeStatus] = useState("");

  const user = getCurrentUser();

  const navigate = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const getPaymentDetails = async () => {
    let paymentType = "conversion_application";
    try {
      const { data } = await GetApplicationFee();
      const info = data.find((x) => x.name.toLowerCase() === paymentType);
      setAdmissionFee(info);
      setResultFee(data[1]);
    } catch (error) {}
  };

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      setApplicationFeeStatus(data.application_fee.payment_status);
      setresultFeeStatus(data.result_checking_fee.payment_status);
    } catch (error) {}
  };

  const payHNDConversionFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateHNDConversionPayment({
        application_id: user.application_id,
      });
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made", warnProp);
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment", successProp);
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      setBtnText("Failed, try again");
      setLoading(false);
      toast.error("Initiating payment failed!", errorProp);
    }
  };

  const payResultCheckFee = async () => {
    try {
      setBtnText("Initializing...");
      setLoading(true);
      const { data } = await InitiateResultCheckingPayment({
        application_id: user.application_id,
      });
      const { status, authorization_url, reference } = data;
      if (status && status === "success") {
        toast.info("Payment already made", warnProp);
        setLoading(false);
        setBtnText("Proceed to payment");
      } else {
        toast.success("Initiating payment", successProp);
        window.location.replace(authorization_url);
        setLoading(false);
      }
    } catch (error) {
      setBtnText("Failed, try again");
      setLoading(false);
      toast.error("Initiating payment failed!", errorProp);
    }
  };

  useEffect(() => {
    getPaymentDetails();
    fetchPaymentStatus();
  }, []);

  return (
    <>
      <div class="page-wrapper">
        <div className="page-content-tab">
          <div class=" mb-5  justify-content-center">
            <div class="page-title-box">
              <div class="float-right">
                <ol class="breadcrumb">
                  {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                  <li class="breadcrumb-item active">Payment</li>
                </ol>
              </div>
              <h4 class="page-title">Payment</h4>
            </div>

            {applicationFeeStatus !== "success" && (
              <ApplicantPayment
                loading={loading}
                btnText={btnText}
                handlePayment={payHNDConversionFee}
                admissionFee={admissionFee}
              />
            )}

            {applicationFeeStatus === "success" && (
              <div class="alert dspg-success" role="alert">
                All payments completed. Check your dashboard to print your
                receipt
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicationPayment;
