import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavHeader from "./components/NavHeader";
import NewsMarquee from "./components/NewsMarquee";
import SliderCarousel from "./components/SliderCarousel";

const ApplicationLandingPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div data-layout="horizontal-tab">
        <NavHeader />
        <div class="" style={{ minHeight: "120vh", paddingTop: "1rem" }}>
          <div class="container">
            <div class="row vh-100 d-flex justify-content-center">
              <div class="col-12">
                <div className="">
                  <div class="card-body">
                    <div class="px-3">
                      <div class="auth-logo-text">
                        <h2 class="mt-0 mb-3 mt-5">Applicant Section</h2>
                        <h4 class="text-danger mb-2">Registration Guide.</h4>
                        <hr />
                        <ul class="list-unstyled faq-qa">
                          <li class="mb-4">
                            <h6 class="">
                              1. We advise you register with a valid email
                              address
                            </h6>
                          </li>
                          <li class="mb-4">
                            <h6 class="">
                              2. You will provide your names and password
                            </h6>
                          </li>
                          <li class="mb-4">
                            <h6 class="">
                              3. After Registration, check your email for login
                              details
                            </h6>
                          </li>
                        </ul>
                      </div>
                      <NewsMarquee />
                      <SliderCarousel />

                      <div class="card-body">
                        <h4 class="header-title mt-0 mb-3">
                          Open applications
                        </h4>
                        <div class="table-responsive browser_users">
                          <table class="table mb-0">
                            <thead class="thead-light">
                              <tr>
                                <th>Code</th>
                                <th>Title</th>
                                <th>Application period</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>XYZ123</td>
                                <td>HND Converstion 2024/2025</td>
                                <td>1 July 2023 - 31 August 2023</td>
                                <td>
                                  <button
                                    className="btn btn-dark"
                                    onClick={() => navigate("/apply/register")}
                                  >
                                    Register
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationLandingPage;
