import { User, CreditCard } from "react-feather";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { VerifyApplicationPayment } from "../../../api/application";
const SmallCard = ({ title, paymentStatus, invoice }) => {
  let abc = paymentStatus === null;

  const navigate = useNavigate();

  console.log(paymentStatus, "Omohhh");

  const paymentRequery = async (ref) => {
    if (!ref) return alert("No Payment Reference Found");
    try {
      const response = await VerifyApplicationPayment(ref);
      toast.success("Verification Successful");
      navigate("/successful", { replace: true });
    } catch (error) {
      toast.error("Verification Failed");
    }
  };

  return (
    <>
      <div class="card report-card" style={{height:"10rem"}}>
        <div
          className={`card-body ${
            paymentStatus === null
              ? "dspg-danger"
              : paymentStatus !== null &&
                paymentStatus?.payment_status === "success"
              ? "dspg-success"
              : paymentStatus !== null &&
                paymentStatus?.payment_status === "pending"
              ? "dspg-warning"
              : ""
          }`}
        >
          <div class="row d-flex justify-content-center">
            <div class="col-8">
              <p class="text-dark font-weight-semibold font-14">{title}</p>
              <h3 class="my-3">
                {paymentStatus === null
                  ? "Not Paid"
                  : paymentStatus !== null &&
                    paymentStatus?.payment_status === "success"
                  ? "Paid"
                  : paymentStatus !== null &&
                    paymentStatus?.payment_status === "pending"
                  ? "Pending"
                  : "Not-Paid"}
              </h3>
              {paymentStatus?.payment_status === "success" &&
              paymentStatus?.paystack_ref ? (
                <button
                  class="btn btn-light"
                  onClick={() => navigate("/invoice", { state: { invoice } })}
                >
                  Print Receipt
                </button>
              ) : paymentStatus?.payment_status === "pending" &&
                paymentStatus?.paystack_ref ? (
                <button
                  class="btn btn-light"
                  onClick={() => paymentRequery(paymentStatus?.paystack_ref)}
                >
                  Requery
                </button>
              ) : null}
              {paymentStatus === null && (
                <button
                  className="btn btn-light waves-effect"
                  onClick={() => navigate("/apply/payment")}
                >
                  Pay fees
                </button>
              )}
            </div>
            <div class="col-4 align-self-center">
              <div class="report-main-icon bg-light-alt">
                <CreditCard class="align-self-center icon-dual-pink icon-lg"></CreditCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallCard;
